<script>
import {defineAsyncComponent, defineComponent} from "vue";
import Header from "@/Components/Header/Header.vue";
const Footer = defineAsyncComponent(() => import('@/Components/Footer/Footer.vue'))
const AdsScript = defineAsyncComponent(() => import('@/Components/AdsScript/AdsScript.vue'))

export default defineComponent(  {
    name: "AppSite",
    components: {
        Header,
        AdsScript,
        Footer
    },

});
</script>

<template>
    <Header/>

    <main>
        <slot/>
    </main>

    <Footer/>

    <AdsScript/>
</template>


