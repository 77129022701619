<script setup>
import moment from "moment";
</script>

<script>
import App from '@/Pages/App.vue'
import HeroSection from '@/Pages/Site/Home/HeroSection/HeroSection.vue'
import Button from '@/Components/Button/Button.vue';
import IconsSvg from '@/Components/IconsSvg/IconsSvg.vue';
import {ref} from 'vue';
import * as func from '@/Helpers/functions';

import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';

const UPDATE_FREQ_MS = 2000


export default {
    components: {
        App,
        HeroSection,
        Button,
        IconsSvg,
        Swiper,
        SwiperSlide,
        InfiniteLoading
    },
    props: {
        rafflesHighlight: Object,
        rafflesOuther: Object,
        rafflesFinish: Object
    },
    data() {
        return {
            online: 100,
            randomOnLine: 100,
            apostadores: 10000,
            basic: this.$inertia.page.props.siteconfig,
            swiperOptions: {
                loop: true,
                autoplay: true,
            },
        }
    },
    methods: {
        randomIntFromInterval(min, max) { // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min)
        },
        randomFunction() {
            let n = this.randomIntFromInterval(0, 10);
            if (n % 2) this.increase();
            else this.decrease();
        },
        increase() {
            let n = this.randomIntFromInterval(4, 12);
            this.randomOnLine += n;
        },
        decrease() {
            let n = this.randomIntFromInterval(3, 9);
            this.randomOnLine -= n;
        },
        /*filteredRafflesStatus(status, highlight) {
            let tempRaffles = this.raffles

            console.log(status)

            tempRaffles = tempRaffles.filter((item) => {
                return (item.status === status && item.highlight === highlight)
            })

            return tempRaffles;
        }*/
    },
    mounted() {
        console.log(this.basic)
    //     /*console.log(this.destaques)
         //console.log('high', this.rafflesHighlight)
         //console.log('outhers', this.rafflesOuther)
         //console.log('finish', this.rafflesFinish)

    //     this.destaques = this.filteredRafflesStatus('Ativo', true)
    //     this.ativas = this.filteredRafflesStatus('Ativo', false)*/
    },
    setup(props) {
       //console.log(props.rafflesFinish)

      // let finish = ref(props.rafflesFinish);

       // const finish = ref([]);

       // finish.value.push(...props.rafflesFinish.data)

        // console.log(finish)

        /*let page = 2;
        const nextPage = ref('?page=' + page);
        const load = async $state => {
            //console.log("loading...");
            try {
                const response = await fetch(nextPage).then(r => r);

                // const json = response.body;
                // console.log(response);
                // const json = await response;
                if (response.length < 10) $state.complete();
                else {
                  //  finish.value.push(...response);

                    // console.log(finish);
                    $state.loaded();
                }
                page++;
            } catch (error) {
                console.log(error)
                $state.error();
            }
        };*/

        return {
            // load,
            // finish,
            // page,
            // func,
            modules: [Autoplay, Navigation, FreeMode],
        };
    },
    watch: {
        randomOnLine: {
            handler(randomOnLine) {
                clearInterval(this._updateTimer)
                if (randomOnLine > 50 && randomOnLine < this.apostadores) {
                    this._updateTimer = setInterval(() => this.randomFunction(), UPDATE_FREQ_MS)
                } else this.randomOnLine += 223;
            },
            immediate: true,
        },
    }
}
</script>

<template>
    <App>
        <section class="pt-20 md:pt-28 pb-4">
            <div class="container max-w-5xl">
                <div class="flex gap-4">
                    <div class="hidden md:block">
                        <div class="avatar w-28 h-28 bg-primary rounded-full flex justify-center items-center">
                            <div class="avatar__img bg-primary">
<!--                                <IconsSvg name="logo-icon" class="w-28 h-28 fill-white"/>-->
                                <img src="/images/avatar-icon.svg" class="w-full h-full" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col justify-center gap-2">
                        <div class="flex items-center justify-between gap-3">
                            <div class="md:hidden">
                                <div class="avatar w-20 h-20 bg-primary rounded-full flex justify-center items-center mb-2">
                                    <div class="avatar__img bg-primary">
                                        <!--                                <IconsSvg name="logo-icon" class="w-28 h-28 fill-white"/>-->
                                        <img src="/images/avatar-icon.svg" class="w-full h-full" alt="">
                                    </div>
                                </div>
                            </div>

                            <div class="flex-1 flex flex-row justify-between items-center gap-1">
                                <h2 class="text-lg font-bold text-black flex">
                                    {{basic?.site_title}}
                                    <IconsSvg name="icon-verification" class="h-5 ml-1"/>
                                </h2>

                                <div class="flex gap-2">
                                    <!--                                    <Button color="outline-colorful">ENVIAR MENSAGEM</Button>-->
                                    <div class="relative hidden">
                                        <span
                                            class="text-xs text-white bg-red p-1 rounded-md absolute -top-4 -left-2 z-10">Em Breve</span>
                                        <Button type="button" color="gray" disabled="false">SEJA AFILIADO</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h1 class="text-base font-medium text-black -mt-2">
                            {{basic?.site_subtitle}}
                        </h1>

                        <ul class="flex gap-5">
                            <li class="text-sm font-bold text-black">
                                {{ randomOnLine }} Online
                            </li>
                            <li class="text-sm md:text-sm font-bold text-black">
                                {{ apostadores }} Fãs
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section v-if="rafflesFinish?.length > 0" class="py-4">
            <div class="container max-w-5xl px-0 lg:px-4">
                <swiper
                    :slidesPerView="3"
                    :centeredSlides="false"
                    :grabCursor="true"
                    :loop="false"
                    :breakpoints="{
                      '540': {
                        slidesPerView: 3,
                      },
                      '768': {
                        slidesPerView: 3,
                      },
                       '960': {
                        slidesPerView: 4,
                        loop: false,
                        centeredSlides: false
                        },
                      '1024': {
                        slidesPerView: 5,
                        loop: false,
                         centeredSlides: false
                      },
                      '1920': {
                        slidesPerView: 8,
                        loop: false,
                        centeredSlides: false
                      },
                    }"
                    :navigation="true"
                    :modules="modules"
                    class="swiper-country"
                >
                    <swiper-slide v-for="(item, index) in rafflesFinish"  :key="index">
                        <a class="py-2 px-5 cursor-pointer w-full flex flex-col items-center gap-3 text-sm text-neutral/70 font-semibold">
                            <figure class="outline outline-offset-4 outline-1 rounded-full overflow-hidden">
                                <img :src="item.raffle_images[0]?.thumb ?? 'images/img-thumb.webp'" class="w-full h-full object-cover">
                            </figure>
                            <span >{{ item?.title }}</span>
                        </a>
                    </swiper-slide>
                </swiper>
            </div>
        </section>

        <section class="py-4">
            <div class="container max-w-5xl px-0 lg:px-4">
                <h2 class="o-title pb-2 mb-4 justify-center text-center uppercase border-b border-black/10">Sorteios</h2>
            </div>

            <div class="container max-w-2xl grid grid-cols-1 gap-6">
                <template v-for="(item, index) in rafflesHighlight" :key="index">
                    <a :href="item.link" class="w-full cursor-pointer">
                        <div class="flex items-center gap-2 md:gap-4 mb-2 md:mb-2">
                            <div
                                class="py-1.5 md:py-1 px-2 md:px-3 rounded-xl border-2 md:border-[3px] border-black flex flex-col items-center">
                                <span class="text-black font-black text-2xl md:text-3xl -mb-2">
                                    {{
                                        item.expected_date ? moment(item.expected_date).format('DD') : ''
                                    }}
                                </span>

                                <span class="text-black uppercase text-sm md:text-base">
                                    {{
                                        new Date(item.expected_date.split('T')[0].replace(/-/g, "/")).toLocaleDateString('pt-BR', {
                                            month: 'short',
                                        })
                                    }}
                                </span>
                            </div>

                            <div class="flex flex-col">
                                <p class="text-base md:text-2xl font-bold text-neutral uppercase">
                                    {{ item.title }}
                                </p>

                                <div class="flex items-center flex-wrap md:gap-2">
                                    <span class="text-sm md:text-base font-semibold text-neutral/60">
                                        {{ item.subtitle }}
                                    </span>

                                    <!-- <span class="px-3 p-1 text-xs md:text-sm rounded-full bg-primary font-bold">
                                         {{
                                             moment(option.date_init).format('DD/MM H:mm')
                                         }}
                                     </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="grid gap-1 md:gap-6">
                            <!-- <template v-for="odd in option.odd" :key="odd.id"> -->
                            <div class="rounded-2xl overflow-hidden">
                                <figure class="aspect-video">
                                    <img :src="item?.new_banner" class="w-full h-full object-cover" alt="">
                                </figure>
                            </div>
                            <!-- </template> -->

                        </div>
                    </a>
                </template>

                <template v-for="(item, index) in rafflesOuther" :key="index">
                    <a :href="item.link" class="w-full cursor-pointer" >
                        <div class="flex items-center gap-2 md:gap-4 mb-2 md:mb-2">
                            <div v-if="item?.expected_date"
                                class="py-1.5 md:py-1 px-2 md:px-3 rounded-xl border-2 md:border-[3px] border-black flex flex-col items-center">
                                <span class="text-black font-black text-2xl md:text-3xl -mb-2">
                                    {{
                                        item.expected_date ? moment(item.expected_date).format('DD') : '0'
                                    }}
                                </span>

                                <span class="text-black uppercase text-sm md:text-base">
                                    {{
                                        item.expected_date ?
                                        new Date(item?.expected_date?.split('T')[0].replace(/-/g, "/")).toLocaleDateString('pt-BR', {
                                            month: 'short',
                                        }) : 'A Definir'
                                    }}
                                </span>
                            </div>

                            <div class="flex flex-col">
                                <p class="text-base md:text-2xl font-bold text-neutral uppercase">
                                    {{ item.title }}
                                </p>

                                <div class="flex items-center flex-wrap md:gap-2">
                                    <span v-if="item.expected_date === ''" class="text-sm md:text-base font-semibold text-neutral/60">
                                        {{ item.subtitle }}
                                    </span>

                                    <div v-else class="">
                                        Sorteio a ser definido
                                    </div>

                                    <!-- <span class="px-3 p-1 text-xs md:text-sm rounded-full bg-primary font-bold">
                                         {{
                                             moment(option.date_init).format('DD/MM H:mm')
                                         }}
                                     </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="grid gap-1 md:gap-6">
                            <div class="rounded-2xl overflow-hidden">
                                <figure class="aspect-video">
                                    <img :src="item?.new_banner ?? './images/img.webp'" class="w-full h-full object-cover" alt="">
                                </figure>
                            </div>
                        </div>
                    </a>
                </template>
            </div>
        </section>
    </App>
</template>

<style lang="scss" scoped>
.c-card__item {
    @apply flex flex-col gap-4 border border-base-100 bg-content p-4 rounded-2xl relative overflow-hidden;

    figure{
        @apply border border-base-100/50 aspect-square overflow-hidden rounded-xl;

        img{
            transition: .2s ease-in-out;
        }
    }

    &:hover{
        figure{
            img{
                transform: scale(1.03);
            }
        }
    }
}

.o-title {
    @apply text-2xl font-bold text-neutral;

    &:after {
        content: '';
        @apply h-[1px] flex-1 mt-1;
    }
}

.c-closed{
    @apply py-0.5 px-8 text-error-bw bg-error font-semibold absolute origin-center top-5 -left-8 -rotate-45;
}

.avatar {
    position: relative;
    z-index: 1;

    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 100%;
        border: 4px solid #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
            border: 2px solid #fff;
        }
    }

    &:before {
        content: '';
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: linear-gradient(rgba(0, 0, 0, 0.66) 0%, #000000);
        z-index: -1;

        @media (max-width: 768px) {
            width: calc(100% + 2px);
            height: calc(100% + 2px);
        }
    }
}
</style>

<style lang="scss">
.swiper-country{
    .swiper-button-prev, .swiper-button-next{
        width: 35px;
        height: 100%;
        top: -10px;
        margin: 0;
        &:after{
            font-size: 16px;
            font-weight: 800;
            color: #000000;
        }
    }

    .swiper-button-prev{
        left: 0;
        background: linear-gradient(-90deg,transparent,rgba(250,250,250,.87) 30%,#f5f5f5 80%);
    }

    .swiper-button-next{
        right: 0;
        background: linear-gradient(90deg,transparent,rgba(255,250,250,.87) 30%,#f5f5f5 80%);
    }
}

</style>
