<script>
import { Link } from '@inertiajs/vue3';
import Button from '@/Components/Button/Button.vue';
import Icon from '@/Components/Icon/Icon.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation, Autoplay, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export default {
    components: {
        Link,
        Icon,
        Button,
        Swiper,
        SwiperSlide,
    },
    props: {
        highlight: Object,
    },
    data() {
        return {
            modules: [Autoplay, Navigation, Keyboard, Pagination],
        }
    },
    methods: {
        goNext() {
            this.$emit('prev');
        },
        goPrev() {
            this.$emit('next');
        },
    }
}
</script>

<template>
    <section id="hero" class="c-hero">
        <div class="container flex">
            <div v-if="highlight.length > 0" class="w-full">
                <swiper ref="swiper"
                        :keyboard="true"
                        :slidesPerView="1"
                        :preventClicks="false"
                        :spaceBetween="15"
                        loop="true" :autoplay="{
                delay: 4500,
                disableOnInteraction: false,
            }" :breakpoints="{

                '768': {
                    slidesPerView: 1,
                },
                '1024': {
                    slidesPerView: 1,

                },
            }" :pagination="{
                clickable: true,
            }" :navigation="{ nextEl: '.custom-next-button', prevEl: '.custom-prev-button' }"
                        :allowTouchMove="false" :grabCursor="false" :centeredSlides="true" :modules="modules"
                        class="swiper-hero">

                    <swiper-slide v-for="(item, key) in highlight" :key="key">
                        <a :href="route('raffle', item.link)" class="w-full h-full rounded-2xl overflow-hidden">
                            <div class="aspect-[1/1] md:aspect-[4/2] overflow-hidden rounded-2xl" :aria-label="item.name">
                                <picture>
                                    <source media="(max-width: 768px)" :srcset="item.raffle_images[0]?.thumb" />
                                    <img class="w-full h-full object-cover" :src="item.new_banner" :alt="item.title" />
                                </picture>
                            </div>

                            <div class="box-banner">
                                <Button type="button" color="primary" class="pulsate-fwd">Adquira e concorra</Button>
                            </div>
                        </a>
                    </swiper-slide>

                    <div class="swipper-navigation">
                        <div class="px-4 md:w-[100%] mx-auto flex justify-between">
                            <button type="button" class="swiper-nav-button custom-prev-button" @click="goPrev">
                                <Icon name="icon-carret-left" class="w-4 h-4 mr-0.5 fill-primary" />
                            </button>

                            <button type="button" class="swiper-nav-button custom-next-button" @click="goNext">
                                <Icon name="icon-carret-right" class="w-4 h-4 ml-0.5 fill-primary" />
                            </button>
                        </div>
                    </div>
                </swiper>
            </div>
        </div>
    </section>
</template>

<style src="./style.scss" lang="scss" />
